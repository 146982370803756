import request from '@/utils/request'


// 查询用户列表
export function listSenseUser(query) {
  return request({
    url: '/senseUser/senseUser/list',
    method: 'get',
    params: query
  })
}

// 查询用户分页
export function pageSenseUser(query) {
  return request({
    url: '/senseUser/senseUser/page',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getSenseUser(data) {
  return request({
    url: '/senseUser/senseUser/detail',
    method: 'get',
    params: data
  })
}

// 新增用户
export function addSenseUser(data) {
  return request({
    url: '/senseUser/senseUser/add',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateSenseUser(data) {
  return request({
    url: '/senseUser/senseUser/edit',
    method: 'post',
    data: data
  })
}

// 删除用户
export function delSenseUser(data) {
  return request({
    url: '/senseUser/senseUser/delete',
    method: 'post',
    data: data
  })
}
